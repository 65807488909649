#verifyAccountPUM, #uploadPhotoPUM { display:none; }
#uploadPhotoMsg a { color:red; font-weight:bold; }

#recentGamesList .game{ display:inline-block; }

.entry-content {
  margin-top: 0px!important;
  position: relative;
}

h1.entry-title {
    display: none;
}

.entry-header.alignwide {
    padding: 0px;
    margin: 0;
    height: 110px;
    background-color: #1f1f1f;
    border-bottom: solid 2px #494949;
}

.site-main {
    background-attachment: fixed;
}

.fix-container br {
    display: none;
}

.welcome-user {
    font-size: .9em;
    padding-left: 15px;
    padding-top: 30px;
}

.welcome {
    color: #fff;
    padding: 15px;
    padding-bottom: 0px;
    font-weight: bold;
    font-size: 1.15rem;
}

.welcome-user .user-name, .welcome-user .loading {
    color: #f6dd01;
    font-weight: bold;
    font-size: 1.15rem;
}

.vip-info-container {
    width: 35%;
    padding: 15px;
    float: left;
}

.tier-info {
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    border-radius: 10px;
    border: solid 1px #ffffff14;
    box-shadow: 0 0 15px #10111259;
    backdrop-filter: blur(10px);
    color: #fff;
    padding: 15px;
    padding-bottom: 50px;
}

.tier-title {
    text-align: center;
    display: block;
    padding: 15px;
    letter-spacing: .1em;
}

.item-container {
    background-image: radial-gradient(95% 100% at 50% 0%,#cbcbcb40 0,#04040436 100%);
    box-shadow: 0 2px 10px #1011123d;
    margin: auto;
    max-width: 500px;
    border-radius: 10px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 5px;
    position: relative;
}

.item-container .container {
    width: 100%;
    padding: 15px 0;
    line-height: 1em;
}

.vip-item-title {
    font-size: .8em;
}

.task {
    font-size: .7em;
    color: #9f9f9f;
    padding-top: 8px;
}

.percentage {
    padding: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-right: 25px;
}

.next-rank {
    display: flex;
    max-width: 500px;
    margin: auto;
    font-size: .7em;
    color: #a7a7a7;
}

.next-rank span {
    width: 100%;
    margin-top: auto;
}

.vip-name {
    text-align: right;
}

.guage {
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    border-radius: 10px;
    pointer-events: none;
}

.account-right-container {
    width: 65%;
    padding: 15px;
    float: left;
}

.amount-info-container {
    display: flex;
    margin: 0 -10px;
}

.permonth-container {
    background-image: radial-gradient(80% 82% at 50% -24%,#28282833 0,#04040475 100%);
    border-radius: 10px;
    border: solid 1px #3e3e3e14;
    box-shadow: 0 0 15px #1011129e;
    backdrop-filter: blur(10px);
    float: left;
    color: #fff;
    font-size: .7em;
    padding: 20px;
    width: 100%;
    margin: 0 10px;
}

span.permonth-deposit {
    font-size: 1.5em;
    font-weight: bold;
}

.right-container {
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    border-radius: 10px;
    border: solid 1px #ffffff14;
    box-shadow: 0 0 15px #10111299;
    backdrop-filter: blur(10px);
    color: #fff;
    width: 100%;
    float: left;
    margin-top: 30px;
    padding: 15px;
    padding-top: 40px;
}

.deposit-top-info {
    display: flex;
}


.retain-levelup-container .amount {
    font-size: 1.5em;
    color: #fff;
    font-weight: bold;
}

.retain-levelup-container {
    text-align: left;
    font-size: .8em;
    padding: 15px;
    margin-bottom: 20px;
    width: 100%;
}

.red-button {
    background-image: radial-gradient(50% 100% at 50% 142%, #dd1212 0, #870404 100%);
    box-shadow: 0 0 5px 0px #4c0000, 0 0 10px 2px #a7000087;
    padding: 6px 18px;
    border-radius: 6px;
    color: #fff;
    text-shadow: 0 0 5px #fff;
    font-size: .8em;
    text-transform: uppercase;
    letter-spacing: .1em;
    cursor: pointer;
    transition: all .7s;
    display: block;
    margin: auto;
    border-width: 2px;
    margin-top: 15px;
}

.account-collapsible {
    padding: 15px;
}

.account-collapsible h3 {
    font-size: .8em;
    max-width: 700px;
    margin: auto;
    padding: 15px 0px;
    padding-left: 30px;
    cursor: pointer;
}

.account-collapsible > div {
    max-width: 700px;
    margin: auto;
}

.account-collapsible .notice {
    background-color: #00000036;
    font-size: .75em;
    padding: 15px;
    border-radius: 10px;
    color: #d3d3d3;
    margin-bottom: 15px;
}

.field-row {
    display: flex;
}

.account-collapsible label {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    background-color: #787878;
    color: #000;
    padding: 10px 15px;
    font-size: .8em;
}

.field-container {
    width: 100%;
    background-color: #444444;
    padding: 10px 15px;
}

.field-container input {
    font-size: 1em;
    padding: 5px;
    width: 100%;
    line-height: 1em;
    background: transparent;
    border: solid 1px #8b8b8b;
    color: #fff;
}

#changePasswordForm > div {
    max-width: 700px;
    margin: auto;
    border-radius: 8px;
    overflow: hidden;
}

.field-row {
    display: flex;
}

#changepwd-alert {
    font-size: .8em;
    font-weight: 400;
    color: #ff5539;
    padding: 15px;
    text-align: center;
}

.deposit-guage-container {
    height: 8px;
    margin: 0px 15px;
    background-color: #bdbdbd33;
    border-radius: 15px;
}

.deposit-guage-container .guage {
    background-color: #d52c2c;
    border: solid 1px #ff2424;
    border-radius: 12px;
    box-shadow: 0 0 8px #c10000, inset 0 0 4px #ffe4e4b3;
    width: auto;
    position: unset;
    height: 100%;
}

.account-collapsible li {
    list-style: none;
  position: relative;
}

.account-collapsible .plus-minus-toggle {
    position: absolute;
    top: 22px;
    left: 5px;
}

.account-collapsible .plus-minus-toggle:before {
  background: white;
  content: '';
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
  transition: transform 500ms ease;
  transform: scale(.9) rotate(-45deg);
}
.account-collapsible .plus-minus-toggle:after {
  background: white;
  content: '';
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
  transition: transform 500ms ease;
  transform: scale(.9) rotate(45deg);
}
.account-collapsible .plus-minus-toggle:after {
  transform-origin: center;
}
.account-collapsible .plus-minus-toggle.collapsed:after {
  transform: rotate(90deg);
}
.account-collapsible .plus-minus-toggle.collapsed:before {
  transform: rotate(-180deg);
}

.vipIcon {
    margin: auto;
    display: block;
    max-width: 260px!important;
}

.item-container .icon {
    padding-top: 6px;
}

.item-container .icon img {
    max-width: 88px;
}
.deposit-btn-mobile {
  display: none;
  text-align: center;
}

.recent-games {
    padding-top: 56px;
    clear: both;
    position: relative;
}

.recent-games h3 {
    font-size: 1.5em;
    font-weight: 600;
    color: #fff;
}

.recent-games .title-line {
    margin-bottom: 25px;
    float: left;
}

#recentGamesList {
    clear: both;
}

#recentGamesList .game {
    display: inline-block;
    width: 19%;
    margin: .5%;
    position: relative;
    /*cursor: pointer;*/
}

.game:hover img {
    filter: unset;
}
.account-navigation a.active {
    color: #fff;
    background: #fff;
    background: radial-gradient(60% 107% at 50% 118%, #8d0000 0, #101331 100%);
    border-bottom: 4px solid #a11414;
}
.featured-item {
    background-image: radial-gradient(80% 82% at 50% -24%,#28282833 0,#04040475 100%);
    border-radius: 10px;
    border: solid 1px #58585870;
    padding: 10px 0px;
    margin: 0px 5px;
    filter: drop-shadow(0px 6px 10px #111);
    width: 100%;
    text-align: center;
    color: #fff;
}

.featured-item img {
    cursor: pointer;
    transform: scale(.9) translate(0px, 0px);
    transition: all .2s;
    height: 98px;
    width: unset;
}

.alert div a { color:red; }

.display-flex {
    display: flex;
    clear: both;
    width: 100%;
}

.entry-content {
  margin-top: 0px!important;
  position: relative;
}

.red-button {
    background-image: radial-gradient(50% 100% at 50% 142%, #dd1212 0, #870404 100%);
    box-shadow: 0 0 5px 0px #4c0000, 0 0 10px 2px #a7000087;
    padding: 6px 18px;
    border-radius: 6px;
    color: #fff;
    text-shadow: 0 0 5px #fff;
    font-size: .8em;
    text-transform: uppercase;
    letter-spacing: .1em;
    cursor: pointer;
    transition: all .7s;
    display: block;
    margin: auto;
    border-width: 2px;
    margin-top: 15px;
}

h1.entry-title {
    display: none;
}

.entry-header.alignwide {
    padding: 0px;
    margin: 0;
    height: 110px;
    background-color: #1f1f1f;
    border-bottom: solid 2px #494949;
}

.fix-container br {
    display: none;
}

.account-container {
    color:#fff;
    padding: 15px;
}

.report-page .account-container {
    max-width: 1180px;
    margin: auto;
    margin: 38px auto;
}

.right-container {
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    border-radius: 10px;
    border: solid 1px #ffffff14;
    box-shadow: 0 0 15px #10111299;
    backdrop-filter: blur(10px);
    color: #fff;
    width: 100%;
    float: left;
    margin-top: 30px;
    padding: 15px;
    padding-top: 40px;
}

.right-container h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 80px;
    margin-bottom: 15px;
}

.all-form-container {
    width: 100%;
}

.deposit-container {
    max-width: 925px;
    margin: auto;
    display: flex;
}

.depositnav-nav.nav-tabs {
    background: #0b0b18;
    border-radius: 5px 0px 0px 5px;
    display: flex;
    overflow: hidden;
    flex-flow: column;
    width: 194px;
}

.depositnav-nav.nav-tabs div {
    cursor: pointer;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    scale: .9;
    transition: .1s all;
}

.depositnav-nav.nav-tabs a.active div {
    scale: 1;
}

.depositnav-nav.nav-tabs img {
    max-width: 30px;
    background-color: #000;
    padding: 5px;
    border-radius: 18px;
    transition: .3s all;
}

.depositnav-nav.nav-tabs a {
    color: #fff;
    text-decoration: none;
    font-size: .9em;
    display: block;
    cursor: pointer;
    box-shadow: inset 0 0 10px #393a4d;
    border-bottom: solid 1px #3b3b3b;
    transition: .3s all;
    width: 170px;
}

.depositnav-nav.nav-tabs a.active {
    background-color: #f5dd00;
    color: #10101b;
    font-weight: 600;
    box-shadow: inset 0 0 10px #fff145;
}






.form-title {
    padding: 10px 15px;
    background-color: #0b0b18;
}

.form-title div {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
}

.form-title img {
    width: 30px;
}

.account-container form {
    border-radius: 0px 5px 5px 0px;
    background-image: radial-gradient(52% 65% at 50% 50%, #ffffff 0, #ffffffb8 100%);
    padding-bottom: 20px;
}

.account-container .notice {
    background-color: #8d8e9b;
    box-shadow: inset 0 0 10px #66667b;
    font-size: .8em;
    padding: 15px;
    border-radius: 10px;
    color: #ffffff;
    margin: 15px;
    text-shadow: 0 0 15px #1a1a1e;
}

.account-container .row {
    margin-left: unset;
    margin-right: unset;
}

.account-container .hLabel {
    color: #fff;
    padding: 4px 22px;
    font-size: .9em;
    background-image: radial-gradient(80% 82% at 50% -24%, #28282833 0, #000a31a6 100%);
    margin-bottom: 0px !important;
    margin-top: 16px;
    text-align: center;
}
.account-container .input-container {
    padding: 5px 15px;
}

.account-container input {
    background-color: white;
    color: #000;
    border: solid 1px #c0c0d5;
    box-shadow: 0 0 10px #28283f47;
    line-height: 1em;
    font-size: .8em;
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
}

.account-container select {
    background-color: white;
    color: #000;
    border: solid 1px #c0c0d5;
    box-shadow: 0 0 10px #28283f47;
    line-height: 1em;
    font-size: .8em;
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
}

.onlinepayment-guide {
    background-image: radial-gradient(60% 100% at 50% 132%,#ededed 0,#b4b3b3 100%);
    box-shadow: 0 0 5px 0 #a3a3a3, 0 0 5px 2px #4a4a4a;
    font-size: .8em;
    font-weight: 600;
    line-height: 1em;
    display: inline-block;
    padding: 10px 15px;
    text-decoration: unset;
    border-radius: 5px;
    border: 1px;
    color: #000!important;
}

.withdraw-container {
    max-width: 925px;
    margin: auto;
    display: flex;
}

.withdraw-nav.nav-tabs {
    background: #0b0b18;
    border-radius: 5px 0px 0px 5px;
    display: flex;
    overflow: hidden;
    flex-flow: column;
    width: 194px;
}

.withdraw-nav.nav-tabs div {
    cursor: pointer;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    scale: .9;
    transition: .1s all;
}

.withdraw-nav.nav-tabs a.active div {
    scale: 1;
}

.withdraw-nav.nav-tabs img {
    max-width: 30px;
    background-color: #000;
    padding: 5px;
    border-radius: 18px;
    transition: .3s all;
}

.withdraw-nav.nav-tabs a {
    color: #fff;
    text-decoration: none;
    font-size: .9em;
    display: block;
    cursor: pointer;
    box-shadow: inset 0 0 10px #393a4d;
    border-bottom: solid 1px #3b3b3b;
    transition: .3s all;
    width: 170px;
}

.withdraw-nav.nav-tabs a.active {
    background-color: #f5dd00;
    color: #10101b;
    font-weight: 600;
    box-shadow: inset 0 0 10px #fff145;
}

.withdraw-nav form {
    border-radius: 0px 0px 5px 5px;
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    padding-bottom: 20px;
}

.withdraw-nav .notice {
    background-color: #00000036;
    font-size: .75em;
    padding: 15px;
    border-radius: 10px;
    color: #d3d3d3;
    margin-bottom: 15px;
    margin-top: 15px;
}

.withdraw-container form {
    border-radius: 0px 5px 5px 0px;
    background-image: radial-gradient(52% 65% at 50% 50%, #ffffff 0, #ffffffb8 100%);
    padding-bottom: 20px;
}

.withdraw-container tr {
    margin: 0px 15px;
    display: flex;
    border-bottom: solid 1px #808080;
}

.withdraw-container tbody tr:first-child {
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    margin-top: 40px;
}

.withdraw-container tbody tr:nth-last-child(2) {
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
}

.withdraw-container tbody tr:nth-last-child(1) {
    border: none;
}

.withdraw-container th {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    background-color: #787878;
    color: #000;
    padding: 10px 15px;
    font-size: .8em;
    font-weight: 400;
}

.withdraw-container td {
    width: 100%;
    background-color: #444444;
    padding: 10px 15px;
}

.withdraw-container .balance {
    font-size: .8em;
}

.bal-avatar > div {
    position: relative;
    background-color: #0006;
    box-shadow: 0 0 5px 0px #4c0000, 0 0 10px 2px #a7000087, inset 0 0 8px #9b0000;
    padding: 6px 18px;
    border-radius: 6px;
    color: #fff;
    text-shadow: 0 0 5px #fff;
    text-transform: uppercase;
    letter-spacing: .1em;
    cursor: pointer;
    transition: all .7s;
    display: block;
    margin: auto;
    border: solid 2px #851515;
    margin-top: 15px;
    text-align: center;
    max-width: 388px;
    font-size: 1.2em;
    font-weight: bold;
}

.remainingrollover {
    background-color: #ffb900;
    box-shadow: inset 0 0 8px #fff42a;
    border: solid 1px #ffc800;
    color: #000;
    padding: 5px;
    font-size: .75em;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 5px;
    border-radius: 6px;
	display:none; 
}

.smallbanner {
    border-radius: 6px;
    overflow: hidden;
    margin: auto;
    margin-bottom: 15px;
    position: relative;
    max-width: 925px;
}

.smallbanner img {
    display: block;
}

.right-container {
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    border-radius: 10px;
    border: solid 1px #ffffff14;
    box-shadow: 0 0 15px #10111299;
    backdrop-filter: blur(10px);
    color: #fff;
    width: 100%;
    float: left;
    margin-top: 30px;
    padding: 15px;
    padding-top: 40px;
}

.share-credit-tc-container {
    width: 100%;
    background-position: left bottom;
    background-size: cover;
    padding: 35px;
}

.share-credit-tc-container h3 {
    font-size: 1.2em;
}

.share-credit-tc-container h4 {
    font-size: .8em;
    margin-top: 35px;
}

.share-credit-tc-container p {
    font-size: .7em;
    margin-left: 22px;
    margin-top: 15px;
    color: #cfc6c6;
}

.share-credit-tc-container ol {
    font-size: .7em;
    margin-left: 36px;
    margin-top: 15px;
    color: #cfc6c6;
}

.share-credit-tc-container ol span {
    display: block;
    margin-left: 20px;
}

.share-credit-rq-container {
    width: 100%;
    background-color: #0000008a;
    padding: 15px;
}

.share-credit-rq-container > div {
    background-image: radial-gradient(60% 101% at 50% -24%,#ffffff3d 0,#46464682 100%);
    border: solid 1px #ffffff2e;
    box-shadow: 0 0 15px #10111259;
    height: 100%;
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
    padding: 25px;
    padding-top: 30px;
}

.from-to-type-container .field-row br {
    display: none;
}

.share-credit-rq-container h3 {
    font-size: 1.2em;
}

.share-credit-rq-container form {
    margin-top: 25px;
}

.share-credit-rq-container br {
    display: none;
}

.share-credit-rq-container label {
    font-size: .7em;
}

.share-credit-rq-container input {
    margin-bottom: 15px;
    padding: 6px;
    font-size: .8em;
    width: 100%;
    border: none;
    background-color: #a5a5a533;
    border-bottom: solid 2px #8d8d8d;
    color: #fff;
}

.submit-container {
    padding: 15px;
}

.share-credit-rq-container input[type="checkbox"] {
    height: 15px;
    width: 15px;
    border: solid 1px #8d8d8d;
    margin-bottom: 0px;
    vertical-align: middle;
}

.checkbox-label {
    color: #a5a5a5;
    margin-left: 8px;
}

.share-credit-rq-container input.red-button {
    border-bottom: none;
    width: unset;
    display: inline-block;
    margin: 0px;
    padding: 10px 15px;
}

.report-info {
    text-align: center;
}

.report-info h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 80px;
}

.report-request h2 {
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    margin-top: 80px;
}

.report-request p {
    font-size: .8em;
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-top: 29px;
}

.report-request .title-line {
    float: unset;
}

p.referral-text {
    display: block;
    font-size: .8em;
    padding: 20px 0px;
}

.report-info ol {
    font-size: .75em;
    margin-left: 45px;
}

.table-fields {
    clear: both;
    background-color: #00000038;
    border: solid 1px #3e3e3e14;
    text-align: center;
}

.from-to-type-container {
    display: inline-block;
}

.from-to-type-container .field-row {
    display: inline-block;
    padding: 0 10px;
}

.from-to-type-container label {
    display: inline-block;
    margin-bottom: 0px;
    font-size: .8em;
    color: #353535;
}

.table-fields input {
    background-color: #ffffff;
    color: #000;
    border: none;
    font-size: 1em;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 0px;
}

.table-fields select {
    background-color: #ffffff;
    color: #000;
    border: none;
    font-size: .9em;
    padding: 5px 10px;
    padding-right: 45px;
    border-radius: 5px;
    margin-left: 0px;
}

.summary h3 {
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.summary-amount {
    overflow: hidden;
    box-shadow: 0 8px 10px #00000040;
    background-image: radial-gradient(80% 82% at 50% -24%,#28282833 0,#04040475 100%);
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 10px;
}

#gr-turnover {
    font-weight: bold;
}

.amount-label {
    font-size: .7em;
}

.report-detail {
    background-image: radial-gradient(50% 100% at 50% 142%,#dd1212 0,#870404 100%);
    box-shadow: 0 0 10px 0 #4c0000, 0 0 1px 1px #ef0000;
    font-size: .8em;
    border-radius: 3px;
    border: 2px;
    padding: 4px 15px;
    max-width: 146px;
    width: 100%;
    margin: auto;
    cursor: pointer;
    text-align: center;
}

.account-content table {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 8px 10px #00000040;
}

.account-content th {
    background-image: radial-gradient(80% 82% at 50% -24%,#28282833 0,#04040475 100%);
    font-size: .8em;
    text-align: center;
    font-weight: 400;
    padding: 10px;
}

.account-content td {
    background-color: #8080804d;
    font-size: .75em;
    text-align: center;
}

#gr-summary{ display:none; }

.summary > div {
    overflow: auto;
    margin-top: 5px;
}

.summary > div table {
    min-width: 856px;
    width: 100%;
}

.permonth-container img {
    margin-bottom: 8px;
}

.featured-icons {
    background: unset;
    clear: both;
}

.featured-icons:before {
    display: none;
}

.icons-container {
    padding: unset;
    padding-top: 0px;
    clear: both;
    display: flex;
}
.bottom-text {
    color: white;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

a.info-icon-link {
    color: #c5c5c5;
    font-size: 1.5em;
    min-width: 28px;
    min-height: 28px;
    display: inline-block;
    text-align: center;
    background-color: #202020;
    border: solid 1px #606060;
    border-radius: 18px;
    box-shadow: 0 0 8px 2px #323232, inset 0 0 8px #686868c7;
    line-height: 1.2em;
    margin-left: 8px;
    transition: all .2s;
    text-decoration: none;
}
a.info-icon-link:hover {
    color: #ffffff;
    background-color: #383838;
    box-shadow: 0 0 8px 2px #7e7e7e, inset 0 0 8px #686868c7;
}

.all-form-container .userBalance {
    border-radius: 10px;
    box-shadow: inset 0 0 15px #2a58ff;
    margin: auto;
    color: #f5dd00;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
    margin-bottom: -10px;
    background-color: #101112;
    max-width: 180px;
    padding: 5px;
}

.formotp {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000eb;
    backdrop-filter: blur(4px);
    top: 0;
    left: 0;
    border-radius: 16px;
    display: flex;
    flex-flow: column;
    padding: 16px;
    justify-content: center;
    z-index: 9;
}

.formotp input {
    padding: 8px;
    border-radius: 5px;
    border: none;
    width: 100%;
    height: 32px;
 }
 .formotp button {
    background: radial-gradient(
       52.78% 52.78% at 50% 50%,
       #ffffff 0%,
       #f5dd00 100%
    ) !important;
    box-shadow: 0px 0px 3px #ffe600;
    color: #00053a !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding: 7px 0;
    border-radius: 5px;
    margin-top: 16px;
 }

 .formotp label {
    color: #cdcdcd;
    font-size: 0.9em;
    text-align: left;
    margin-top: 12px;
    display: block;
    padding-bottom: 6px;
}

.success-otp input {
    padding: 8px;
    border-radius: 5px;
    border: none;
    width: 100%;
    height: 32px;
 }
 .success-otp button {
    background: radial-gradient(
       52.78% 52.78% at 50% 50%,
       #ffffff 0%,
       #f5dd00 100%
    ) !important;
    box-shadow: 0px 0px 3px #ffe600;
    color: #00053a !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding: 7px 0;
    border-radius: 5px;
    margin-top: 16px;
 }

 .success-otp label {
    color: #cdcdcd;
    font-size: 0.9em;
    text-align: left;
    margin-top: 12px;
    display: block;
    padding-bottom: 6px;
}

.success-otp {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000eb;
    backdrop-filter: blur(4px);
    top: 0;
    left: 0;
    border-radius: 16px;
    display: flex;
    flex-flow: column;
    padding: 16px;
    justify-content: center;
    z-index: 9;
}

.notice {
    padding: 6px;
    font-size: .8em;
    color: #ffec63;
}

.success-otp .otplabel {
    text-align: center;
    font-weight: bold;
}

.success-otp .otpinput {
    background-color: #060606;
    border: solid 1px #fff !important;
    color: #fff;
    text-align: center;
    box-shadow: inset 0 0 10px #7d7d7d;
}

.otp-close {
    background: unset;
    background-color: #696969;
    border: solid 1px #575757;
    box-shadow: 0 0 10px #535353;
    line-height: 1em;
    color: #ffffff;
    font-size: 1.2em;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 12px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    font-weight: bold;
    z-index: 9;
}

.verificationform {
    padding: 30px;
    max-width: 600px;
    margin: auto;
}

.form-login .forgetpw-trigger {
    background: unset ! Important;
    box-shadow: unset;
    border: solid #afafaf 1px;
    color: #9f9f9f ! Important;
    font-size: .8em;
    font-weight: 400;
    text-transform: unset;
 }

 .all-form-container .alert {
    color: #000;
    font-size: .9em;
}

.userID {
    display: flex;
    align-items: center;
}

.userID a {
    margin-left: -10px;
}

.userID a img {
    max-width: 26px;
}

.balance-brakedown {
    color: #616161;
    background-color: #fff;
    padding: 12px;
    margin-top: 5px;
    margin-bottom: -10px;
    font-size: .8em;
    border-radius: 10px;
    font-weight: 600;
    width: 100%;
    box-shadow: 0 0 15px #0000008c, inset 0 0 15px #0b0b4275;
}

.balance-brakedown div {
    padding: 4px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.balance-brakedown div span {
    font-weight: bold;
    color: #000;
    font-size: 1.2em;
}

.balance-brakedown button {
    background: radial-gradient(52.78% 52.78% at 50% 50%, #0a137a 0%, #00053a 100%) !important;
    box-shadow: 0px 0px 3px #004987;
    color: #fff;
    padding: 5px;
    width: 100%;
    border-radius: 14px;
}
.userBalance {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.userBalance button {
    padding: 2px;
    border-radius: 10px;
    line-height:.4em;
    margin-left: 6px;
}

.userwrapper {
    height: unset!important;
}

.accountsub {
    display: flex;
    gap: 5px;
    flex-flow: column;
    position: absolute;
    right: 4px;
    top: 60px;
    padding: 6px;
    background-color: #fff;
    font-weight: 600;
    font-size: 1em;
    border-radius: 6px;
    box-shadow: 0 0 15px #0000008c, inset 0 0 15px #0b0b4275;
    cursor: pointer;
}

.accountsub a {
    padding: 6px 20px;
    box-shadow: inset 0 0 12px #6c6d79;
    border-radius: 6px;
    text-decoration: unset;
    color: #f6dd01;
    background-color: #000225;
}

.accountsub a.logout {
    background-color: #232323;
    color: #e3e3e3;
    font-weight: 400;
}

.welcome-user #handleVerifyClick {
    background: radial-gradient(52.78% 52.78% at 50% 50%, #ffffff 0%, #f5dd00 100%) !important;
    border-radius: 10px;
    border: solid 1px #ffffff14;
    box-shadow: 0 0 6px #fff700;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: #000225;
    text-shadow: 0 0 6px #fff;
    padding: 8px 15px;
    text-decoration: unset;
    font-weight: bold;
    cursor: pointer;
    display: none;
}

.welcome-user #handleVerifyClick.verifyprofile {
    display: block;
}

.verification-container {
    top: 0;
    left: 0;
    display: flex;
}

.modal-container {
    position: relative;
}

@media (max-width: 840px) {
    .modal-close {
        z-index: 999999;
    }
    .modal {
        display: block;
        overflow: scroll;
    }
    .mobile-view .HaveAccount {
        flex-flow: row;
        position: relative;
        justify-content: space-between;
        align-items: center;
        margin-top: 0px;
        padding: 6px 0px;
        color: #e0e0e0;
        font-size: .9em;
        border-top: solid 1px #243044;
    }

    .isLoggedIn .mobile-view .HaveAccount {
        display: none;
    }
    
    .mobile-view .HaveAccount a {
        width: unset;
        padding: 6px 15px;
        margin: 0;
        border: solid 1px #2e3161;
        font-size: .9em;
        border-radius: 10px;
    }
    .modal {
        z-index: 999999!important;
    }
}